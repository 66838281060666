/* CONTAINER STYLES */
.theme_container__SHN68 {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 150px 120px;
  background-color: #ffffff;
}

.theme_container__SHN68 > .theme_mainContent__21rsn {
  width: 90%;
  display: flex;
  max-width: 1440px;
}

@media (min-width: 391px) and (max-width: 768px) {
  .theme_container__SHN68 {
    padding: 72px 24px !important;
  }
}

@media (min-width: 320px) and (max-width: 390px) {
  .theme_container__SHN68 {
    padding: 72px 0 !important;
  }
}

.theme_primaryFont__zCju0 {
  font-family: 'DM Sans', serif !important;
  font-style: normal !important;
  text-decoration: none !important;
  color: #000000;
}

.theme_secondaryFont__laqXu {
  font-family: 'Heebo', serif;
  font-style: normal;
}

/* TEXT STYLES */
.theme_heading__qRDra {
  font-weight: 700 !important;
  line-height: 120% !important;
  margin: 0;
  color: #000000;
}

.theme_heading-1__3bYQg {
  font-size: clamp(2.125rem, 1.3003rem + 3.1873vw, 5.125rem);
  font-size: clamp(2.125rem, 1.3003rem + 3.1873vw, 4.125rem);
}

.theme_heading-2__OO_0t {
  font-size: clamp(1.875rem, 1.2908rem + 2.2576vw, 4rem);
}

.theme_heading-3__1Ap7W {
  font-size: clamp(1.625rem, 1.2814rem + 1.328vw, 2.875rem);
}

.theme_heading-4__Cs8_C {
  font-size: clamp(1.375rem, 1.2719rem + 0.3984vw, 1.75rem);
}

.theme_heading-5__cw17j {
  font-size: clamp(1.25rem, 1.2156rem + 0.1328vw, 1.375rem);
}

.theme_heading-7__XIuHI {
  font-size: clamp(1rem, 0.9643rem + 0.1786vw, 1.125rem);
}

.theme_quote-1__Tk0qM {
  font-weight: 500;
  line-height: 150%;
  font-size: clamp(1.25rem, 1.1125rem + 0.5312vw, 1.75rem);
}

.theme_body__CrIza {
  font-weight: 400;
  line-height: 150%;
  color: #000000;
  margin: 0;
}

.theme_body-1___zL67 {
  font-size: clamp(1.25rem, 1.2156rem + 0.1328vw, 1.375rem);
}

.theme_body-2__B_9M6 {
  font-size: 18px;
}

.theme_body-3__V0hcS {
  font-size: 16px;
}

.theme_body-4__Q4D6F {
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}

.theme_label-2__vy0FD {
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 2px;
  color: #000000;
  margin: 0;
}

.theme_description__KOAER {
  font-size: clamp(1.125rem, 1.0563rem + 0.2656vw, 1.375rem);
  line-height: 150%;
  color: rgba(0, 0, 0, 0.75);
  text-align: left;
  margin: 0;
}

/* COMMON BUTTON STYLES */
.theme_buttonText__rUyy_ {
  line-height: 24px !important;
  letter-spacing: 2px !important;
  text-align: center !important;
  color: #000000;
  text-decoration: none !important;
}

.theme_button__uYvTy {
  border-radius: 14px !important;
  box-sizing: border-box !important;
  transition: color 0.2s !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: background 0.2s !important;
  transition: outline 0.1s !important;
  z-index: 0 !important;
  border-style: none !important;
}

.theme_fullWidth__BxXxT {
  width: 100%;
}

.theme_button-1__8HYLR {
  font-size: 18px !important;
  height: 84px !important;
}

.theme_button-2__cdu8l {
  font-size: 16px !important;
  height: 64px !important;
}

.theme_button-3__mS28N {
  font-size: 14px !important;
  height: 54px !important;
}

.theme_button-4__NnfWb {
  font-size: 14px !important;
  height: 44px !important;
}

/* PRIMARY BUTTON STYLES */
.theme_primaryButton__R70_v {
  background-color: #dbc6aa;
  transition: background 0.2s;
}

.theme_primaryButton__R70_v:hover {
  color: #ffffff;
  background-color: #1e3b47;
}

.theme_primaryButton__R70_v:active {
  outline: 6px solid #dee0de;
}

.theme_primaryButton__R70_v:disabled {
  background-color: rgba(33, 50, 37, 0.15) !important;
  color: rgba(33, 50, 37, 0.25) !important;
}

.theme_primaryButtonDark__UoyPY {
}

.theme_primaryButtonDark__UoyPY:hover {
  background-color: #ffffff !important;
  color: #000000;
}

.theme_primaryButtonDark__UoyPY:disabled {
  color: rgba(255, 255, 255, 0.15);
  background-color: rgba(255, 255, 255, 0.15);
}

.theme_primaryButtonDark__UoyPY:active {
  outline: 6px solid rgba(255, 255, 255, 0.15) !important;
}

/* SECONDARY BUTTON STYLES */
.theme_secondaryButton__e7Oj1 {
  background-color: rgba(33, 50, 37, 0.15) !important;
  -webkit-backdrop-filter: blur(10px) !important;
          backdrop-filter: blur(10px) !important;
  transition: background 0.2s !important;
}

.theme_secondaryButton__e7Oj1:hover {
  background-color: rgba(33, 50, 37, 0.25) !important;
}

.theme_secondaryButton__e7Oj1:active {
  outline: 6px solid rgba(33, 50, 37, 0.15) !important;
}

.theme_secondaryButton__e7Oj1:disabled {
  background-color: white;
  border: 1px solid rgba(33, 50, 37, 0.15) !important;
  color: rgba(33, 50, 37, 0.25) !important;
}

.theme_secondaryButtonDark__Gmkpi {
  color: white;
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  transition: background 0.2s;
}

.theme_secondaryButtonDark__Gmkpi:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.25) !important;
}

.theme_secondaryButtonDark__Gmkpi:active {
  outline: 6px solid rgba(255, 255, 255, 0.15) !important;
}

.theme_secondaryButtonDark__Gmkpi:disabled {
  background-color: black;
  border: 6px solid rgba(255, 255, 255, 0.15) !important;
  color: rgba(255, 255, 255, 0.25) !important;
}

/* LINK BUTTON STYLES */
.theme_linkButton__mzSta {
  font-size: 16px;
  letter-spacing: 1px;
  border-radius: 0;
  height: 44px;
  background-color: white;
  border-bottom: 4px solid #dbc6aa;
  padding: 0;
  text-decoration: none;
  border-style: none;
}

.theme_linkButton__mzSta:hover {
  background-color: white;
  border-bottom: 4px solid #000000;
}

.theme_linkButton__mzSta:disabled {
  background-color: white;
  color: rgba(33, 50, 37, 0.25);
  border-bottom: 4px solid rgba(33, 50, 37, 0.25);
}

/* Text Colors */
.theme_textPrimaryMain__0gnqh {
  color: #1e3b47;
}

/* Background Colors */
.theme_lightBackground__j__JG {
  background: #1e3b47;
}
.Footer_container__YfQ4y {
  flex-direction: column;
  justify-content: space-between;
  padding: 48px 120px;
  min-height: 100px;
  background: #0c232d;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

@media (min-width: 391px) and (max-width: 768px) {
  .Footer_container__YfQ4y {
    padding: 48px 32px !important;
  }
}

@media (min-width: 320px) and (max-width: 390px) {
  .Footer_container__YfQ4y {
    padding: 48px 24px !important;
  }
}

.Footer_mainContent__ZGWAs {
  justify-content: space-between;
}

.Footer_logoContainer__ALGIU {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
}

.Footer_logoContainer__ALGIU > img {
  align-self: flex-start;
  margin-bottom: 32px;
}

.Footer_logoContainer__ALGIU > span.Footer_hrefsColumnHeader__28tsc {
  margin-bottom: 16px !important;
  align-self: flex-start;
}

.Footer_logoContainer__ALGIU > p.Footer_addressText__BlO65 {
  text-align: left !important;
  margin-top: 0;
}

.Footer_hrefsContainer__Jw6Gr {
  display: flex;
  width: 70%;
  justify-content: flex-end;
}

.Footer_hrefsContainerGroup__zEMbV {
  display: flex;
}

.Footer_hrefsColumn__NYpAb {
  display: flex;
  flex-direction: column;
  max-width: 180px;
}

.Footer_hrefsColumnHeader__28tsc,
.Footer_hrefsColumnItem__FcjOH,
.Footer_addressText__BlO65 {
  justify-content: flex-start;
  text-align: left;
}

.Footer_hrefsColumnHeader__28tsc {
  margin-bottom: 24px;
  height: 17px !important;
  color: rgba(255, 255, 255, 0.5);
}

.Footer_hrefsColumnItem__FcjOH {
  margin-bottom: 16px;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  margin: 0;
  line-height: 1.35;
}

.Footer_addressText__BlO65 {
  margin-bottom: 16px;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  margin: 0;
}

.Footer_bottomContent__RT9Bo {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-weight: 400;
  font-size: 14px;
  margin-top: 24px;
  max-width: 1440px;
}

.Footer_trustBox__nnZjb {
  display: flex;
  justify-content: center;
  height: 84px;
  align-items: center;
}

@media only screen and (max-width: 960px) {
  .Footer_container__YfQ4y {
    padding: 48px 0;
  }
}

@media only screen and (max-width: 768px) {
  .Footer_mainContent__ZGWAs {
    flex-direction: column;
    align-items: flex-start;
  }

  .Footer_hrefsColumn__NYpAb {
    margin: 0 16px 32px 0;
  }

  .Footer_hrefsContainer__Jw6Gr {
    width: 100%;
    margin-top: 48px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 0.5fr);
    grid-gap: 16px;
    gap: 16px;
  }
}

.Footer_hrefsContainer__Jw6Gr > div.Footer_hrefsColumn__NYpAb:not(:last-child) {
  margin-right: 0;
}

.Footer_hrefsContainerGroup__zEMbV {
  flex-wrap: wrap;
}

.Footer_bottomContent__RT9Bo {
  flex-direction: column;
}

.Footer_bottomContent__RT9Bo > span {
  margin-bottom: 16px;
}

.Footer_copyright__W8ZKy {
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: white;
}

@media only screen and (min-width: 769px) {
  .Footer_hrefsContainer__Jw6Gr > div.Footer_hrefsColumn__NYpAb:not(:last-child) {
    margin-right: 40px;
  }
}

.Header_containerOpaque__EmyHS {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100px;
  background-color: white;
  z-index: 100;
  transition-timing-function: ease;
  transition: background-color 0.3s;
  transition: height 0.5s;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 15%);
}

.Header_container__O36kb {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 25%, rgba(255, 255, 255, 0) 100%);
  color: white;
  transition-timing-function: ease;
  transition: background-color 0.3s;
  transition: height 0.5s;
  z-index: 100;
}

.Header_contents__Qt82v {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 48px 32px;
  width: 100%;
  max-width: 1440px;
}

.Header_headerButtons__q7rjZ {
  display: flex;
}

.Header_menuContainer__v_bhA {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.Header_menuButton__zCiFK {
  display: flex;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 2px;
  padding: 0 12px;
  text-decoration: none !important;
  cursor: pointer;
  border-style: none;
  background-color: transparent;
}

.Header_menuIconButton__WhnOO {
  color: white;
  display: flex;
  align-items: center;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 62px !important;
  width: 71px;
  padding: 0 0;
  cursor: pointer;
  border-style: none;
  background: transparent;
}

.Header_menuDropdown__a0cSb {
  top: 40px !important;
  background: #ffffff;
  border: 1px solid lightgrey;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.45) !important;
  border-radius: 16px !important;
  position: absolute;
  width: max-content;
  padding: 4px;
}

.Header_menuItem__z6Sey {
  display: flex !important;
  align-items: center !important;
  border-radius: 4px !important;
  font-weight: 700 !important;
  padding: 8px 20px;
}

.Header_extendedMenu__vscCc {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  gap: 20px;
  padding: 20px;
  cursor: pointer;
}

.Header_subMenuItem__qp9HC {
  display: block;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 8px 20px;
  font-size: 12px;
  cursor: pointer;
  line-height: 18px;
  letter-spacing: 1px;
}

.Header_menuItemLink__i78OF {
}

.Header_menuItem__z6Sey > img {
  margin-right: 8px;
}

.Header_menuItem__z6Sey:hover {
  background: rgba(33, 50, 37, 0.15);
}

.Header_subMenuItem__qp9HC:hover {
  background: rgba(33, 50, 37, 0.05);
}

.Header_buttonContainer__fD4Mg {
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.Header_buttonContainerMenu__mjL0t {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.Header_buttonContainer__fD4Mg > button:first-child {
  margin-right: 12px;
}

.Header_drawerButton__aHJLr {
  background-color: transparent;
  border-style: none;
  display: none;
}

.Header_secondaryButtonDark___jCVX {
  width: 109px;
}

.Header_secondaryButton__BnxdB {
  width: 109px;
}

.Header_buttonMenu__cUT7H {
  width: 135px !important;
  margin: 0 16px 16px 16px;
}

.Header_primaryButton__smDZ7 {
  width: 141px;
}

.Header_primaryButtonDark__3Cc1t {
  width: 141px;
}

.Header_drawerMenu__SJ_bM {
  background-color: #1e3b47 !important;
  color: white !important;
  width: 30%;
  padding-top: 50px;
}

.Header_menuCloseButton__limB_ {
  color: white;
  width: 60px;
  position: absolute;
  right: 24px;
  top: 32px;
}

.Header_optionGrid__kg_wb {
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  padding: 0px 24px 0px;
  box-sizing: border-box;
  width: 80%;
  cursor: pointer;
}

.Header_drawerMenuAccordion__exmGR {
  background-color: #1e3b47 !important;
  border-radius: 0;
  box-shadow: none;
  padding: 24px 16px;
}

.Header_drawerMenuAccordionTitle__EL3SZ {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Header_drawerMenuAccordionTitle__EL3SZ > img {
  margin-right: 8px;
}

.Header_drawerText__bgPnP {
  font-style: normal;
  font-weight: 700;
}

.Header_drawerContent__nPzbD {
}

.Header_drawerMenuItem__PWKtP {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 2px;
  color: #ffffff;
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  padding: 0px 24px;
  cursor: pointer;
}

.Header_drawerMenuSubItem__TpvXj {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.85);
}

.Header_drawerMenuSubLinkItem__RUrJp {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.85);
}

.Header_socialIcons__Fr9N7 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Header_socialIcons__Fr9N7 > img {
  margin: 8px 24px;
}

.Header_clickOutside__f8bC7 {
  position: fixed;
  z-index: 1;
  top: 280px;
  left: 0px;
  height: 50px;
  width: 100vw;
}

@media only screen and (max-width: 1369px) {
  .Header_menuContainer__v_bhA {
    display: none;
  }

  .Header_drawerMenu__SJ_bM {
    width: 50%;
  }

  .Header_drawerButton__aHJLr {
    display: block;
  }
}

.Header_drawerMenu__SJ_bM {
  position: absolute;
  top: 0px;
  left: calc(100vw - 400px);
  width: 400px;
  height: 100vh;
  overflow: auto;
}

@media only screen and (max-width: 767px) {
  .Header_container__O36kb,
  .Header_containerOpaque__EmyHS {
    height: 70px;
  }

  .Header_contents__Qt82v {
    padding: 0;
  }

  .Header_headerLogo___7p71 {
    height: 40px;
    margin-left: 16px;
  }

  .Header_buttonContainer__fD4Mg {
    display: none;
  }

  .Header_drawerMenu__SJ_bM {
    width: 100vw;
    left: 0px;
  }

  .Header_contents__Qt82v {
    padding: 0;
  }
}

@media only screen and (max-width: 1440px) {
  .Header_contents__Qt82v {
    width: 95%;
  }
  .Header_drawerMenu__SJ_bM {
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.45) !important;
  }
}

/* latin-ext */
@font-face {
  font-family: '__DM_Sans_c48b40';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_c48b40';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_c48b40';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_c48b40';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_c48b40';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_c48b40';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_c48b40';src: local("Arial");ascent-override: 94.37%;descent-override: 29.49%;line-gap-override: 0.00%;size-adjust: 105.12%
}.__className_c48b40 {font-family: '__DM_Sans_c48b40', '__DM_Sans_Fallback_c48b40';font-style: normal
}

body {
  margin: 0;
}

@media only screen and (min-width: 767px) {
  .hide-on-desktop {
    display: none; 
  }
}

@media only screen and (max-width: 768px) {
  .main-container {
    overflow-x: hidden !important;
  }
}
.root {
  max-width: 1200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 80px;
  min-height: calc(100vh - 64px);
  position: relative;
}
.root figure {
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}
.root h1 {
  font-size: 2.5rem;
  color: #1E3B47;
  font-weight: 500;
  padding: 16px 0 0px;
}
.root h2 {
  color: #575760;
  font-size: 2.0rem;
  font-weight: 400;
  padding: 16px 0;
}
.root h3 {
  color: #575760;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
  padding: 16px 0;
}
.root h4 {
  color: #575760;
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;
  padding: 16px 0;
}
.root h5 {
  color: #575760;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
}
.root p {
  color: #575760;
  line-height: 1.9rem;
  font-size: 1.05rem;
}
.root .wp-block-buttons {
  margin-bottom: 24px;
}
.root .wp-block-button,
.root .action-button,
.root .action-link-button {
  background: #1E3B47;
  padding: 12px 16px;
  border-radius: 100px;
  display: inline-block;
  width: max-content;
  margin-right: 8px;
  margin-bottom: 12px;
  border: none;
  color: #fff;
}
.root .wp-block-button:hover,
.root .action-button:hover,
.root .action-link-button:hover {
  cursor: pointer;
  background: #122025;
}
.root .wp-block-button:active,
.root .action-button:active,
.root .action-link-button:active {
  opacity: 0.5;
}
.root .wp-block-button a,
.root .action-button a,
.root .action-link-button a {
  color: #fff;
  text-decoration: none;
}
.root ol {
  margin: 8px 0;
}
.root ul {
  margin: 40px 0;
}
.root li {
  margin: 8px 0;
  font-size: 1.05rem;
  color: #575760;
}
.root img {
  max-width: 100%;
  max-height: 350px;
  object-fit: cover;
  border-radius: 4px;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.root .wp-block-image {
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}
.aligncenter img {
  display: block !important;
  margin: 40px 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 100%;
}
.root .wp-block-image figure img {
  display: block;
  margin: 40px 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.root .are-vertically-aligned-center {
  align-items: center;
}
.root .wp-block-jetpack-layout-grid {
  display: block;
  margin: 0px;
  padding: 32px 0;
}
.root .wp-block-jetpack-layout-grid.size-full {
  margin: 0px;
}
.root .has-base-background-color,
.root .has-base-2-background-color {
  position: relative;
}
.root .has-background {
  padding: 32px 0;
}

@media (max-width: 960px) {
  .root {
    padding: 16px 0px;
  }
  .root h1 {
    padding: 0px 32px;
  }
}
