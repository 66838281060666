body {
  margin: 0;
}

@media only screen and (min-width: 767px) {
  .hide-on-desktop {
    display: none; 
  }
}

@media only screen and (max-width: 768px) {
  .main-container {
    overflow-x: hidden !important;
  }
}