.containerOpaque {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100px;
  background-color: white;
  z-index: 100;
  transition-timing-function: ease;
  transition: background-color 0.3s;
  transition: height 0.5s;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 15%);
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 25%, rgba(255, 255, 255, 0) 100%);
  color: white;
  transition-timing-function: ease;
  transition: background-color 0.3s;
  transition: height 0.5s;
  z-index: 100;
}

.contents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 48px 32px;
  width: 100%;
  max-width: 1440px;
}

.headerButtons {
  display: flex;
}

.menuContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.menuButton {
  composes: primaryFont from './theme.module.css';
  display: flex;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 2px;
  padding: 0 12px;
  text-decoration: none !important;
  cursor: pointer;
  border-style: none;
  background-color: transparent;
}

.menuIconButton {
  color: white;
  display: flex;
  align-items: center;
  backdrop-filter: blur(10px);
  border-radius: 62px !important;
  width: 71px;
  padding: 0 0;
  cursor: pointer;
  border-style: none;
  background: transparent;
}

.menuDropdown {
  top: 40px !important;
  background: #ffffff;
  border: 1px solid lightgrey;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.45) !important;
  border-radius: 16px !important;
  position: absolute;
  width: max-content;
  padding: 4px;
}

.menuItem {
  composes: primaryFont from './theme.module.css';
  display: flex !important;
  align-items: center !important;
  border-radius: 4px !important;
  font-weight: 700 !important;
  padding: 8px 20px;
}

.extendedMenu {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  cursor: pointer;
}

.subMenuItem {
  composes: primaryFont from './theme.module.css';
  display: block;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 8px 20px;
  font-size: 12px;
  cursor: pointer;
  line-height: 18px;
  letter-spacing: 1px;
}

.menuItemLink {
  composes: primaryFont from './theme.module.css';
}

.menuItem > img {
  margin-right: 8px;
}

.menuItem:hover {
  background: rgba(33, 50, 37, 0.15);
}

.subMenuItem:hover {
  background: rgba(33, 50, 37, 0.05);
}

.buttonContainer {
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.buttonContainerMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.buttonContainer > button:first-child {
  margin-right: 12px;
}

.drawerButton {
  background-color: transparent;
  border-style: none;
  display: none;
}

.secondaryButtonDark {
  composes: button-3 from './theme.module.css';
  composes: secondaryButtonDark from './theme.module.css';
  width: 109px;
}

.secondaryButton {
  composes: button-3 from './theme.module.css';
  composes: secondaryButton from './theme.module.css';
  width: 109px;
}

.buttonMenu {
  composes: button-2 from './theme.module.css';
  width: 135px !important;
  margin: 0 16px 16px 16px;
}

.primaryButton {
  composes: button-3 from './theme.module.css';
  composes: primaryButton from './theme.module.css';
  width: 141px;
}

.primaryButtonDark {
  composes: button-3 from './theme.module.css';
  composes: primaryButtonDark from './theme.module.css';
  width: 141px;
}

.drawerMenu {
  background-color: #1e3b47 !important;
  color: white !important;
  width: 30%;
  padding-top: 50px;
}

.menuCloseButton {
  color: white;
  width: 60px;
  position: absolute;
  right: 24px;
  top: 32px;
}

.optionGrid {
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  padding: 0px 24px 0px;
  box-sizing: border-box;
  width: 80%;
  cursor: pointer;
}

.drawerMenuAccordion {
  background-color: #1e3b47 !important;
  border-radius: 0;
  box-shadow: none;
  padding: 24px 16px;
}

.drawerMenuAccordionTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.drawerMenuAccordionTitle > img {
  margin-right: 8px;
}

.drawerText {
  composes: primaryFont from './theme.module.css';
  font-style: normal;
  font-weight: 700;
}

.drawerContent {
  composes: secondaryFont from './theme.module.css';
}

.drawerMenuItem {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 2px;
  color: #ffffff;
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  padding: 0px 24px;
  cursor: pointer;
}

.drawerMenuSubItem {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.85);
}

.drawerMenuSubLinkItem {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.85);
}

.socialIcons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialIcons > img {
  margin: 8px 24px;
}

.clickOutside {
  position: fixed;
  z-index: 1;
  top: 280px;
  left: 0px;
  height: 50px;
  width: 100vw;
}

@media only screen and (max-width: 1369px) {
  .menuContainer {
    display: none;
  }

  .drawerMenu {
    width: 50%;
  }

  .drawerButton {
    display: block;
  }
}

.drawerMenu {
  position: absolute;
  top: 0px;
  left: calc(100vw - 400px);
  width: 400px;
  height: 100vh;
  overflow: auto;
}

@media only screen and (max-width: 767px) {
  .container,
  .containerOpaque {
    height: 70px;
  }

  .contents {
    padding: 0;
  }

  .headerLogo {
    height: 40px;
    margin-left: 16px;
  }

  .buttonContainer {
    display: none;
  }

  .drawerMenu {
    width: 100vw;
    left: 0px;
  }

  .contents {
    padding: 0;
  }
}

@media only screen and (max-width: 1440px) {
  .contents {
    width: 95%;
  }
  .drawerMenu {
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.45) !important;
  }
}
