.root {
  max-width: 1200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 80px;
  min-height: calc(100vh - 64px);
  position: relative;
}
.root figure {
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.root h1 {
  font-size: 2.5rem;
  color: #1E3B47;
  font-weight: 500;
  padding: 16px 0 0px;
}
.root h2 {
  color: #575760;
  font-size: 2.0rem;
  font-weight: 400;
  padding: 16px 0;
}
.root h3 {
  color: #575760;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
  padding: 16px 0;
}
.root h4 {
  color: #575760;
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;
  padding: 16px 0;
}
.root h5 {
  color: #575760;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
}
.root p {
  color: #575760;
  line-height: 1.9rem;
  font-size: 1.05rem;
}
.root .wp-block-buttons {
  margin-bottom: 24px;
}
.root .wp-block-button,
.root .action-button,
.root .action-link-button {
  background: #1E3B47;
  padding: 12px 16px;
  border-radius: 100px;
  display: inline-block;
  width: max-content;
  margin-right: 8px;
  margin-bottom: 12px;
  border: none;
  color: #fff;
}
.root .wp-block-button:hover,
.root .action-button:hover,
.root .action-link-button:hover {
  cursor: pointer;
  background: #122025;
}
.root .wp-block-button:active,
.root .action-button:active,
.root .action-link-button:active {
  opacity: 0.5;
}
.root .wp-block-button a,
.root .action-button a,
.root .action-link-button a {
  color: #fff;
  text-decoration: none;
}
.root ol {
  margin: 8px 0;
}
.root ul {
  margin: 40px 0;
}
.root li {
  margin: 8px 0;
  font-size: 1.05rem;
  color: #575760;
}
.root img {
  max-width: 100%;
  max-height: 350px;
  object-fit: cover;
  border-radius: 4px;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.root .wp-block-image {
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.aligncenter img {
  display: block !important;
  margin: 40px 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 100%;
}
.root .wp-block-image figure img {
  display: block;
  margin: 40px 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.root .are-vertically-aligned-center {
  align-items: center;
}
.root .wp-block-jetpack-layout-grid {
  display: block;
  margin: 0px;
  padding: 32px 0;
}
.root .wp-block-jetpack-layout-grid.size-full {
  margin: 0px;
}
.root .has-base-background-color,
.root .has-base-2-background-color {
  position: relative;
}
.root .has-background {
  padding: 32px 0;
}

@media (max-width: 960px) {
  .root {
    padding: 16px 0px;
  }
  .root h1 {
    padding: 0px 32px;
  }
}